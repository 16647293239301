/* GorillaProfile.css */

@import url('https://fonts.googleapis.com/css2?family=Germania+One&family=Inter&family=Nunito:ital,wght@0,300;0,400;0,600;0,700;1,600;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap');

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}


@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 2.0s ease-out, transform 2.2s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}



.event {
  animation: fadeIn 1s forwards;
}


.profile-container {
  display: inline-block;
  flex-direction: column;
  align-items: flex-start;
  max-width: 6000px; /* Adjust as necessary */
  width: 100%; /* Ensure the container takes up the full width */
  overflow-x: hidden;
  overflow-y: hidden; /* Hide horizontal scrollbar */
  /* margin-left: -10px; */
  flex-grow: 2;
  margin: 0 auto;
  /* take up entire viewport */  
  margin-right: auto; /* Keep this to maintain the container in the center */
  margin-left: calc(-50% + width/2 - desired_shift);
  transform: translateX(-110px);

  background-color: #ececec; /* Light grey background */
}


.row {

margin-left: 70px;
}

/* dddadadaadad */

.profile-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: adds shadow for depth */
}

.profile-image-container {
  position: absolute;
  /* No need to set width and height here unless you want to constrain the size of the container */
}
.profile-image {
  border-radius: 0;
  border-radius: 40%;
  box-shadow: -2px 6px 8px #6e6b56;
  height: 200px;
  margin-left: 2px;
  margin-top: 10px;
  object-fit: cover;
  position: relative;
  transform: translateY(-10%);
  width: 197px;

}

.cover-photo-container {
  position: relative;
  width: 100%; /* Full width of the container */
  height: 220px; /* Adjust the height as needed */
  overflow: hidden; /* Ensures the image is clipped to the container */
  margin-top: -300px;
  z-index: 1;
  padding-left: 15px;
}

.cover-photo {
  border-bottom-left-radius: 70% 10%;
  border-bottom-right-radius: 50% 10%;
  height: 100%;
  min-height: 110px;
  object-fit: cover;
  object-position: inherit;
  width: 99.5%;
}



.col-md-4, .col-md-8 {
  flex: 1; /* Allow both columns to grow equally */
  margin: 3rem; /* Add some space around */
}
/* Assuming this is for a Bootstrap column within a row */
.col-md-8 {
  flex: flex-start; /* Adjusts the basis for the flex item */
  width: calc(70% - 10px); /* Default for Bootstrap col-md-8 */
  display: block; /* Adjust this to 'flex' if you want to use flexbox properties */
  padding: 4rem; /* Add some padding inside the column */
  background: #fff; /* White background for the content area */ /* Softer rounded corners */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Subtle shadow for depth */
  font-family: 'Roboto', sans-serif; /* Roboto font for modern look */
  font-weight: 400; /* Normal font weight */
  margin: -95px 1rem -0.5rem; /* Space around the column */
  overflow: hidden; /* Ensures nothing spills out of the border-radius */
  position: relative;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 20px; /* Adjust as necessary for desired curvature */
  border-bottom-right-radius: 20px;
  padding-top: -4px;
  z-index: 0;

}

.col-md-4 {
    align-self: start; /* Align the image to the top */
    order: 0;
    z-index: 2; /* Make image appear on the left */
    margin-left: -2px; /* Adjust as needed to move left */
    margin-top: 20px; /* Adjust as needed to move down */
    border-bottom-left-radius: 20; /* To match the right image */
    border-bottom-right-radius: 0; /* To match the right image */
    border-radius: 20%;
    position: relative;
  }

  .about-section {
    display: flex;
    flex-direction: column; /* Stack about items vertically */
    position: absolute; /* Position absolutely within the relative parent */
    bottom: 0; /* Align to the bottom of the parent */
    left: 0; /* Align to the left of the parent */
    background: white; /* Or any color as per design */
    padding: 10px; /* Add some padding */
    border-top-right-radius: 10px; /* Curve the top right corner */
    border-top-left-radius: 10px; /* Curve the top left corner */
    z-index: 1; /* Ensure it's above other elements */
    margin-left: 170px;
    margin-bottom: -5px;
  }

  .about-name {
    font-size: 1.5em; /* Larger font size for the name */
    font-weight: bold; /* Make the name bold */
    margin-bottom: 0.5em;
    z-index: 2;
    margin-top: 10px; 
    margin-left: -160px;/* Space between name and role */
    position: absolute;
    font-family: Nunito;
    font-weight: 700;
    font-style: italic;
  }

  .about-role {
  font-size: 16px;
  color: #5C4F5C; /* Grey color for the role text */
  margin-bottom: 1em; /* Space between role and about items */
  margin-top: 40px; 
  margin-left: -160px;/* Space between name and role */
  position: absolute;
  font-family: Nunito;
  font-weight: 400;
  font-style: normal;
  line-height: normal; 
}
  
.about-item {
  display: flex;
  align-items: center; /* This should vertically center the content if it's not already */
  margin-bottom: 5px;
}
  
.about-icon {
  width: 24px;
  height: 24px;
  margin-right: 6px;
  transform: translateY(-10%);
  position: relative;
  top: 50%;
}

.about-icon[src="/Assets/images/birthday.svg"] {
  position: relative;
  top: -4px; /* Adjust this value as needed to move the icon up */
}
  
  .about-text {
  font-size: 1em; /* Adjust font size as needed */
  color: #333;
  font-family: Nunito;
  font-weight: 400;
  font-style: normal;
  line-height: normal; 
  }


.gallery-preview {
  animation: slideInFromRight 5s ease both; /* Added fade-in animation */
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 10px;
  padding: 20px;
}
.gallery-preview::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.gallery-preview img {
  width: auto;  /* Allow the width to adjust automatically */
  height:300px; /* Allow the height to adjust automatically */
  margin-right: 10px;
  border-radius: 20px;  /* Space between images */
}

.gallery-preview img:hover{
  transform: scale(1.05)
}

.gallery-container {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  gap: 10px; /* Keep your gap if you want space between items */
  padding: 20px;
  justify-content: space-around;
  background: white;
  border-radius: 30px;
  box-shadow: 0 30px 20px rgba(0, 0, 0, 0.1);
  z-index: 1; /* Or space-between, depending on your layout */
  margin-top: 10px;
  margin-left: 50px;
  margin-right: 50px;
}

.img-fluid {
  animation: slideInFromRight 2s ease both; /* Added fade-in animation */
  width: 100%;
  height: auto;
  border-radius: 20px;
  box-shadow: 0 10px 6px rgba(0, 0, 0, 0.1);
}

.gallery-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.gallery-container img {
  flex: 1 1 auto; /* Adjust this to set the basis for each image */
  width: auto; /* Adjust the percentage for the number of items per row you want, minus the gap */
  height: auto; /* Keep the original aspect ratio */
  margin-bottom: 20px;
  margin-top: 20px; /* If you want space between rows */
}

.bio-section {
  animation: fadeInLeft 1s ease both;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 112.5px;
  padding: 25px;
  text-align: justify;
  background-color: #ffffff; /* Set bio background to white */
  border-radius: 10px; /* Rounded corners for the bio section */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: adds shadow for depth */
  max-width: 800px; /* Adjust as necessary */
  max-height: 1000px;
  width: 100%; /* Take the full width of the container */
  box-sizing: border-box; /* Include padding in the width */
}

.bio-section p {
  font-family: Nunito;
  font-weight: 400;
  font-style: norma l;
  font-size: 16px;
  color: var(--gray-800, #384247);
  line-height: normal;
  margin-bottom: 0;
}

/*  Timeline */


.timeline::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
.timeline {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 60px;
  z-index: index 0;
}

.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: #6E6B56;;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
  z-index: 0;
}

.container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
  display: inline-block;
  margin: 10px;
  z-index: 1;
}



.container::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -3px;
  background-color: #6E6B56;
  border: 1px solid #6E6B56;
  top: 15px;
  border-radius: 50%;
  z-index: 2;
  /* fill the circle */

  /* fill the circle  */
  

}

.left {
  left: 0;
}

.right {
  left: 50%;
}

.left::before, .right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  border: medium solid white;
}

.left::before {
  right: 30px;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}

.right::before {
  left: 30px;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

.right::after {
  left: -22px;
  z-index: 2;
}

.left::after {
  right: -3.3px;
  z-index: 2;
}




.content {
  padding: 20px 30px;
  background-color: white;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  align-items: center;
}

.content p{
  font-family: Nunito; 
  font-weight: thin 100;
}

.content:hover {
  transform: scale(1.1)
}
div.content h2 {
  font-family: Roboto;
  font-weight: 700;
  font-style: italic;
}

/* Responsive adjustments */
@media screen and (max-width: 600px) {
  .container {
    width: 100%;
    padding: 10px 40px;
    display: block;
  }

  .left::after, .right::after {
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .left, .right {
    justify-content: center;
    text-align: center;
  }


}

/* Your custom button styles */


.tabs {
  display: flex;
  justify-content: flex-start; /* This will align the tabs to the right */
  padding: 10px; /* Adjust padding as needed */
  margin-left: 120px;
}

.tabs button {
  padding: 2px 10px;
  border: none;
  background-color: transparent;
  margin-right: 20px;
  color: black;
  cursor: pointer;
  transition: border-color 0.2s, color 0.2s;
  font-family: Nunito;
  font-weight: 300; 
  font-style: normal;
  font-size: 16px;
}

.tabs button:hover {
   /* Add the border on hover */
  color: #000000; /* Change the text color on hover */
  transform: scale(1.05); /* Add a slight scale effect on hover (optional) */
  background-color: transparent;
}

/* Apply the active-tab styles */
.tabs button.active-tab {
  color: #6E6B56;
  font-weight: 700;
  font-style: normal; 
}















 .btn btn-outline-primary {
  color: #6E6B56;
  border-color: #6E6B56;
}

/* .btn-primary  {
width: 61px;
height: 26.432px;
flex-shrink: 0;
color: #6E6B56;
font-family: Nunito;
font-size: 20px;
font-style: normal;
font-weight: 300;
line-height: normal;
} */

.back-button-container:hover {
  color: white; /* Change text color to white on hover */
  background-color: #6E6B56;
  background: #6E6B56; /* Change background color on hover */
  outline-color:#000000;
}

.modal-footer-buttons {
  display: flex;
  justify-content: flex-end; /* Align the buttons to the right */
  padding: 0.5rem; /* Same padding as default modal-footer */
}




.btn-save {
  /* Add specific styles for your save changes button here */
  /* Example: */
  background-color: #000000; /* Green color for the button */
  color: white;
  position: absolute;
  justify-content: start;
}

.btn-save:hover {
  background-color: #06f73b; /* Darker green on hover */
}

.btn-close {
  background-color: #ff1505; /* Red color for the Close button */
  color: white;
  border: none;
}

.btn-close:hover {
  background-color: #d32f2f; /* Darker red on hover */
}





















/* Admin 

/* Edit Profile button styling */
.edit-profile-btn {
  background-color: transparent;
  outline: #0056b3;
  color:black;
  border-radius: 5px;
  font-size: 16px;
  position: fixed;
  bottom: 0; /* Place it at the bottom of the viewport */
  left: 80px;
  z-index: 100; /* Adjust the z-index as needed */
  cursor: pointer;
}
  
  .edit-profile-btn:hover {
    color:white;
    background-color: #0056b3; /* Darker shade of blue for hover effect */
  }
  




















































  /* Modal Styling */
  .modal-content {
    border-radius: 10px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .modal-header {
    border-bottom: 1px solid #dee2e6;
    background-color: #007bff;
    color: white;
    padding: 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .modal-body {
    padding: 20px;
  }
  
  .modal-footer {
    border-top: 1px solid #dee2e6;
    padding: 15px;
  }
  
  /* Form Styling */
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
  
  .form-control {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ced4da;
  }
  
  /* Gallery Editing in Modal */
  .gallery-edit-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
  }
  
  .gallery-image-preview {
    width: 100px;
    height: 100px;
    border-radius: 5px;
    object-fit: cover;
  }
  
  .add-gallery-url {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .add-gallery-url input {
    flex-grow: 1;
  }
  
  .add-gallery-url button {
    background-color: #28a745; /* Green color for add button */
    color: white;
    border: none;
    padding: 6px 12px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .add-gallery-url button:hover {
    background-color: #218838; /* Darker green on hover */
  }
  
  .remove-gallery-btn {
    background-color: #dc3545; /* Red color for remove button */
    color: white;
    border: none;
    padding: 6px 12px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .remove-gallery-btn:hover {
    background-color: #c82333; /* Darker red on hover */
  }
  

  .posts-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .post {
    border: 1px solid #ddd;
    padding: 16px;
    border-radius: 8px;
  }
  
  .post-image {
    max-width: 100%;
    height: auto;
    display: block;
    margin-top: 8px;
  }

/* For larger screens where you want the profile image and details side by side */
@media (min-width: 768px) {
  .profile-container {
    display: flex;
    flex-direction: start;
    justify-content: center; /* Space out children */
    align-items: flex-start;
   gap: 2px;
   margin-left: 250px;
}
}

@media (max-width: 700px) {

  .cover-photo {
    width: 99.5%;
    height: 99%;
    object-fit: cover;
    object-position: center;
    border-bottom-left-radius: 70% 10%; /* Decreased vertical radius */
    border-bottom-right-radius: 50% 10%; /* Decreased vertical radius */
    min-height: 100px;
  }



  .about-section {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    width: 100%; /* Ensure the section is full width */
    padding: 10px; /* Consistent padding around the section */
    box-sizing: border-box; /* Include padding in width calculation */
  }
  
  .about-name,
  .about-role {
    text-align: center; /* Center the text */
    width: 100%; /* Full width to align with the parent's width */
    margin-bottom: 5px;
    margin-right: 615px; 
    margin-top: 9px;
    margin-left: -50px;/* Space between the name/role and the items below */
  }
  
  .about-item {
    display: flex;
    justify-content: space-around; /* Evenly distribute items across the space */
    align-items: center; /* Align items vertically */
    width: 100%; /* Take up the full width of the parent */
    margin-bottom: 5px; /* Space between each item */
  }
  
  
  .about-text {
    display: inline-flex; /* Display inline-flex to keep icon and text aligned */
    align-items: center;
    margin-right: 300px /* Align icon and text vertically */
  }
  
  .about-text
  .about-icon {
    margin-right: 3px; /* Space after the icon */
  }/* Spacing between icon and text, adjust as needed */
  
}


  /* Adjustments for smaller screens */
  @media (max-width: 768px) {
    .modal-content {
      margin: 20px;
    }
  
    .gallery-image-preview {
      width: 80px;
      height: 80px;
    }
  }
  

  /* Adjustments for iPhone 14 */
  @media (max-width: 431px) {
    .modal-content {
      margin: 10px;
    }
  
    .gallery-image-preview {
      width: 60px;
      height: 60px;
    }
    .profile-container {
      display: flex;
      align-items: flex-start;
      max-width: 6000px;
      width: 100%;
      overflow-x: hidden;
      overflow-y: hidden;
      margin-left: -10px;
      flex-grow: 2;
      margin: -7px auto;
      margin-right: auto;
      margin-left: calc(-50% + width/2 - desired_shift);
      transform: translateX(-38px);
      background-color: #ececec;
      justify-content: space-between;
      flex-wrap: wrap;
      flex-direction: column;
  }
  
    .bio-section {
      animation: fadeInLeft 1s ease both;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 2px 4px #0000001a;
      box-sizing: border-box;
      margin-bottom: 14px;
      margin-left: 83px;
      margin-top: 13px;
      max-height: 3028px;
      max-width: 307px;
      padding: 11px;
      text-align: justify;
      width: 100%;
  }

  .profile-image {
    border-radius: 0;
    border-radius: 40%;
    box-shadow: -2px 6px 8px #6e6b56;
    height: 115px;
    margin-left: 2px;
    margin-top: 10px;
    object-fit: cover;
    position: relative;
    transform: translateY(-10%);
    width: 101px;
  }

  .about-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 3px;
    box-sizing: border-box;
    flex-wrap: nowrap;
    margin-left: 243px;
    margin-bottom: -93px;
    margin-top: -154px;
}

  .about-name {
      font-size: 1.5em;
      font-weight: bold;
      margin-bottom: 0.5em;
      z-index: 2;
      position: absolute;
      font-family: Nunito;
      font-weight: 700;
      font-style: italic;
  }
  
  .about-role {
      font-size: 16px;
      color: #5C4F5C;
      margin-bottom: 1em;
      margin-top: 45px;
      margin-left: -50px;
      position: absolute;
      font-family: Nunito;
      font-weight: 400;
      font-style: normal;
      line-height: normal;
  }

  .timeline {
    position: relative;
    padding-left: 30px; /* Provide some space on the left for the line */
    margin-left: 80px;  /* Represent the timeline line */
  }

  /* Hide the left-pointing before/after pseudo-elements */
  .container.left::before,
  .container.left::after,
  .container.right::before,
  .container.right::after {
    content: none;
  }

  /* Adjust the container for the timeline content */
  .container {
    width: calc(100% - 150px); /* Full width minus padding for the line */
    margin-bottom: 10px; /* Space between entries */
  }

  /* Force all content to the right of the timeline */
  .container.left, 
  .container.right {
    align-items: flex-start; /* Align to the start of the flex container */
  }

  /* Adjust the content within the container */
  .content {
    margin-left: 10px; /* Space from the timeline */
    padding: 2px;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  }

  /* Ensure that fade-in animation sections are visible */
  .fade-in-section.is-visible {
    opacity: 1; /* Adjust as needed for your fade-in effect */
    transform: none; /* Reset any transformations */
  }

  .right {
    left: 43%;
    padding: 2px;
    width: 50%;
  }

  .left {
    left: -14%;
    padding: 1px;
    width: 50%;
  }

  .tabs {
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    margin-left: 92px;
  }
  
  .posts-list {
    display: grid;
    place-items: center;
    flex-direction: column;
    justify-content: center;
    animation: fadeInLeft 1s ease both;
    margin-top: -5px;
    margin-bottom: 10px;
    margin-left: 45.5px;
    margin-right: -19px;
    padding: 20px;
    text-align: justify;
  }
  
  p.caption {
    font-size: 15px;
    font-family: Nunito;
    font-weight: 600;
    margin-bottom: -22px;
    margin-left: -6px;
  }
  
  .post-image {
    width: 90%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    margin-left: 1px;
    margin-bottom: 6px;
    margin-top: 32px;
  }
  .post-video {
    width: 100%;
    height: auto;
    padding: 25px;
    border-radius: 52px;
    transition: transform 0.3s ease;
    margin-top: 10px;
  }

  
.gallery-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px;
  justify-content: center;
  background: white;
  border-radius: 30px;
  box-shadow: 0 30px 20px rgba(0, 0, 0, 0.1);
  z-index: 1;
  margin-top: 18px;
  margin-left:  86px;
  margin-right: 4px;
}

.tabs {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  margin-left: 110px;
}

}


/* Adjustments for iPhone 12
@media (max-width: 393px) {
  .modal-content {
    margin: 10px;
  }

  .profile-container {
    display: flex;
    align-items: flex-start;
    max-width: 6000px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    margin-left: -10px;
    flex-grow: 2;
    margin: -7px auto;
    margin-right: auto;
    margin-left: calc(-50% + width/2 - desired_shift);
    transform: translateX(-44px);
    background-color: #ececec;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
}

  .bio-section {
    animation: fadeInLeft 1s ease both;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px #0000001a;
    box-sizing: border-box;
    margin-bottom: 17px;
    margin-left: 84.5px;
    margin-top: 16px;
    max-height: 3000px;
    max-width: 302px;
    padding: 11px;
    text-align: justify;
    width: 100%;
  }

    .gallery-preview img {
      width: auto;
      height: 229px;
      margin-right: 10px;
      border-radius: 20px;
  }

  .about-role {
    font-size: 16px;
    color: #5C4F5C;
    margin-bottom: 1em;
    margin-top: 45px;
    margin-left: -47px;
    position: absolute;
    font-family: Nunito;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
}

.about-name {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 0.5em;
  z-index: 2;
  margin-top: 17px;
  margin-left: -61px;
  position: absolute;
  font-family: Nunito;
  font-weight: 700;
  font-style: italic;
}


.tabs {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  margin-left: 100px;
}



p.caption {
  font-size: 15px;
  font-family: Nunito;
  font-weight: 600;
  margin-bottom: -22px;
  margin-left: -6px;
}

.post-image {
  width: 90%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  margin-left: 1px;
  margin-bottom: 6px;
  margin-top: 32px;
}
.post-video {
  width: 100%;
  height: auto;
  padding: 25px;
  border-radius: 52px;
  transition: transform 0.3s ease;
  margin-top: 10px;
}

.right {
  left: 42%;
  padding: 2px;
  width: 50%;
}


}



 */

