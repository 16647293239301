/* Full-screen layout */
body, html {
    height: 100%;
    margin: 0;
    font-family: Arial, sans-serif;
    overflow-y: scroll !important;
    
}

.landing-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

#bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; /* Ensure canvas is below the content */
}

.overlay-content {
    position: absolute;
    top: 60%; /* Adjust this value to position the content below the PNG asset */
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}


.overlay-content h1, .overlay-content p, .overlay-content a {
    color: #ffffff; /* White text for visibility against 3D background */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Shadow for better readability */
}


.gorilla-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow-y: auto; /* Enable vertical scrolling */
    background-color: #f9f6f1;
  }
  
  .back-button {
    position: absolute;
    top: calc(100% - 10px); /* Adjust as needed */
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    font-size: 24px;
    color: #8d8c8c;
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: none;
    opacity: 0; /* Start with the button invisible */
    transition: opacity 0.5s, transform 2s ease; /* Smooth transition for opacity and transform */
    transform-origin: center; /* Ensure the button scales from its center */
  }
  
  
  @media (min-width: 431px) {
    .gorilla-container {
      height: 100vh;
    }
  
    .back-button {
      top: calc(100% - 20px); /* Adjust as needed */
      padding: 20px 40px;
      font-size: 48px;
    }
  }